.ws-input {
  height: 53px;
  width: 65%;
  border: 1px solid #868686;
  border-radius: 8px;
  text-align: left;
  padding: 10px;
  margin-bottom: 17px;
}

.ws-sub-text {
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.21px;
}

.ws-sub-sub-text {
  color: #242424;
  font-size: 16px;
  letter-spacing: -0.21px;
  line-height: 19px;
}

.ws-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.ws-input-container {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
}

.ws-sub-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ws-btn-container {
  display: flex;
  width: 70%;
  justify-content: center;
  margin-top: 70px;
}

.ws-title-light {
  color: #000000;
  font-size: 36px;
  letter-spacing: -0.48px;
}

.ws-title-medium {
  color: #242424;
  font-size: 36px;
  letter-spacing: -0.48px;
}

.link {
  font-weight: 500;
}

.logo {
  height: 150px;
}