.emoji-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  padding: 20px;
}

.dimmed {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: none;
  border: none;
  opacity: 0.3;
}

.active-emoji {
  display: flex;
  flex-direction: column;
  height: 134;
  border-radius: 20px;
  border: none;
  border-style: none;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #4A90E2;
  padding: 20px;  
}

.active-emoji:focus {
  outline: none;
}

.active-emoji > p {
  margin: 0;
}

.respond {
  width: 80%;
}