.content-container {
  display: flex;
  width: 100%;
  min-height: 532px;
  align-items: center;
  flex-direction: column;
  background-color: #F7F7F7;
}

.tabs-container {
  display: flex;
  justify-content: space-evenly;
  width: 30%;
  position: relative;
  bottom: 155px;
}

.adherence-tab-active {
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: #FFFFFF;
  border-radius: 20px;
  height: 75px;
  color:  #27325B;
  font-size: 20px;
  padding: 20px;
}

.daily-tab-active {
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: #FFFFFF;
  border-radius: 20px;
  height: 75px;
  color: #27325B;
  font-size: 20px;
  padding: 20px;
}

.adherence-tab {
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-radius: 20px;
  height: 75px;
  color:  #4A90E2;
  font-size: 20px;
  padding: 20px;
}

.daily-tab {
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-radius: 20px;
  height: 75px;
  color:  #4A90E2;
  font-size: 20px;
  padding: 20px;
}

.content-card {
  width: 70%;
  height: fit-content;
  background-color: #FFFFFF;
  border-radius: 40px;
  position: relative;
  bottom: 170px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 2px 8px 0 rgba(164,164,164,0.5);
}