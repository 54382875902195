.input-container {
  width: 90%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #9B9B9B;
  border-radius: 20px;
  height: 73px;
  max-height: 73px;
  background-color: #fff;
  margin-top: 30px;
  margin-bottom: 34px;
}

.pagination-container {
  display: flex;
  width: 50%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1%;
}

.daily-tab {
  color: #006DF0;
  font-size: 18px;
}

.daily-tab-active {
  color: #232425;
  font-size: 18px;
}

.page {
  color: #4A90E2;
  cursor: pointer;
  background: none;
  border: none;
}

.active-page {
  background-color: #4A90E2;
  color: #FFFFFF;
  border-radius: 0px;
  width: 5%;
  text-align: center;
  border: none;
  cursor: pointer;
}