.patient-info-container {
  width: 90%;
  height: 127px;
  min-height: 127px;
  background-color: #F7F7F7;
  border-radius: 30px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: row;
}

.img-container {
  width: 20%;
  height: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient-data-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  min-height: 100px;
  justify-content: center;
}

.rs-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin-left: 5%;
}

.locker-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
}

.locker {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
.sub-locker {
  display: flex;
  flex-direction: row; 
  justify-content: space-around;
}

.title-text {
  font-size: 1vw;
  color: #9B9B9B;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
}

.sub-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 50%;
  }

.content-text {
  font-size: 1.1vw;
  color: #4A4A4A;
  text-align: center;
}

.rs-name-text {
  font-size: 2.2vw;
  color: #000000;
}

.img {
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-d {
  height: 70px;
  width: 70px;
  border-radius: 50px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-img {
  width: 100%;
  border-radius: 50px;
}

.options {
  width: 40%;
}

.rs-options-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 450px;
}

.option {
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
  height: 65%;
  background-color: #69AE3B;
  margin-right: 19px;
  color: #ffffff;
  font-size: 1.2vw;
  border: none;
}

.option-sml {
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100%;
  background-color: #69AE3B;
  margin-right: 19px;
  color: #ffffff;
  font-size: 1.1vw;
  border: none;
}

.option-covid {
  background-color: #961B71;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
  height: 65%;
  margin-right: 19px;
  color: #ffffff;
  font-size: 1.2vw;
  border: none;
}

.option-covid-sml {
  background-color: #961B71;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100%;
  margin-right: 19px;
  color: #ffffff;
  font-size: 1.1vw;
  border: none;
}

.vital {
  background-color: #1057AC;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
  height: 65%;
  margin-right: 19px;
  color: #ffffff;
  font-size: 1.2vw;
  border: none;
}

.vital-sml {
  background-color: #1057AC;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height:100%;
  margin-right: 19px;
  color: #ffffff;
  font-size: 1.1vw;
  border: none;
}

.option-narcotic {
  padding: 10px;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 30%;
  height: 65% !important;
  background-color: #DE5A48;
  margin-right: 19px;
  cursor: grab;
  color: #ffffff;
  height: 75%;
  font-size: 1.2vw;
  border: none;
}

.option-narcotic-sml {
  padding: 10px;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  align-items: center;
  width: 100px;
  height: 100% !important;
  background-color: #DE5A48;
  margin-right: 19px;
  cursor: grab;
  color: #ffffff;
  height: 75%;
  font-size: 1.1vw;
  border: none;
}

.option-refill {
    padding: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 30%;
    height: 65% !important;
    background-color: #F5A623;
    margin-right: 19px;
    color: #ffffff;
    height: 75%;
    font-size: 1.2vw;
    border: none;
}

.option-refill-sml {
  padding: 10px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100% !important;
  background-color: #F5A623;
  margin-right: 19px;
  color: #ffffff;
  height: 100%;
  font-size: 1.1vw;
  border: none;
}

.space {
  margin-left: 21%;
}