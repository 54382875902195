.status-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  background-color: #F6F6F6;
  min-height: 124px;
  border-radius: 40px;
}

.status-bar-container-refill {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  background-color: #F6F6F6;
  min-height: 124px;
  border-radius: 40px;
}

.patient-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  margin-left: 1%;
}

.patient-name-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  margin-left: 3%;
}

.patient-progress-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.patient-img-sb {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sb-avatar {
  height: 80px;
  width: 80px;
}

.salute-text {
  font-size: 1.5vw;
  color: #000000;
}

.sb-name-text {
  font-size: 2.5vw;
}

.oval {
  height: 54px;
  width: 54px;
  border-radius: 50px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line {
  width: 5%;
  border: 1px solid #E5E5E5;
  height: 1px;
}

.line-xl {
  width: 50%;
  border: 1px solid #E5E5E5;
  height: 1px;
}