.rn-canvas {
  border: 1px solid #CFCFCF;
  width: 70%;
  height: 196px;
  border-radius: 20px;
  background-color: #F8FAFC;
}

.nrml-canvas {
  border: 1px solid #CFCFCF;
  width: 90%;
  height: 268px;
  border-radius: 20px;
  background-color: #F8FAFC;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.other-nrml-canvas {
  border: 1px solid #CFCFCF;
  width: 90%;
  height: 268px;
  border-radius: 20px;
  background-color: #F8FAFC;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.full-screen {
  border: 1px solid #CFCFCF;
  border-radius: 20px;
  background-color: #F8FAFC;
  position: absolute;
  margin: 0px;
}

.other-full-screen {
  border: 1px solid #CFCFCF;
  border-radius: 20px;
  background-color: #F8FAFC;
  position: absolute;
  left: 500;
  margin: 0px;
}