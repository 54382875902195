  .status-title-text-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 70%;
}

.emojis-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.status-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.break {
  width: 100%;
  border: 1px solid #E5E5E5;
  height: 1px;
}
