.input {
  width: 100%;
  min-width: 223px;
  height: 53px;
  max-height: 73px;
  border-radius: 10px;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.search {
  width: 100%;
  min-width: 223px;
  height: 70px;
  max-height: 70px;
  padding: .375rem .75rem;
  font-size: 1.5vw;
  font-weight: 400;
  line-height: 1.5;
  color: #9B9B9B;
  border-radius: 20px;
  background-color: #fff;
  background-clip: padding-box;
  text-align: center;
  border: none;
}