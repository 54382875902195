.modal-background {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0%;
  background-color: #00000080;
}

.reasons {
  min-height: 406px;
  min-width: 363px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 0 24px 0 rgba(0,0,0,0.22), 0 24px 24px 0 rgba(0,0,0,0.3);
}

.options {
  height: 475px;
  width: 602px;
  border: 1px solid #979797;
  border-radius: 20px;
  background-color: #FFFFFF;
}

.inventory {
  height: 321px;
  width: 479px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 0 24px 0 rgba(0,0,0,0.22), 0 24px 24px 0 rgba(0,0,0,0.3);
}

.schedule {
  height: 309px;
  width: 229px;
  border: 1px solid #D7D7D7;
  border-radius: 24px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.r-title {
  color: rgba(0,0,0,0.87);
  font-family: "Open Sans";	
  font-size: 20px;	
  font-weight: 600;
  padding: 10px;
}

.s-title {
  color: #4A4A4A;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 21px;
  text-align: center;
  padding: 10px;
}

.sub-text {
  color: rgba(0,0,0,0.54);
  font-family: "Open Sans";	
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
}

.sub-text-med {
  color: rgba(0,0,0,0.54);
  font-family: "Open Sans";	
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  padding: 10px;
}

.modal-checkbox-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.break-reasons {
    width: 100%;
    border: 1px solid #4A90E2;
    height: 1px;
}

.s-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule-btn {
  height: 36px;
  width: 188px;
  border: none;
  background-color: none;
  opacity: 0.63;
  border-radius: 10px;
}

.options-name-txt {
  color: #4A4A4A;	
  font-family: Roboto;	
  font-size: 36px;
  line-height: 42px;
}

.options-txt {
  color: #4A4A4A;
  font-family: Roboto light;
  font-size: 24px;
  line-height: 42px;
}