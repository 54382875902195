.flex-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.left {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
}

.right {
  background-image: url('../../shared/images/background-family.jpg');
  background-size: cover;
  background-repeat: round;
  background-blend-mode: multiply;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
}

.login-form {
  display: flex;
  flex-direction: column;
  min-height: 562px;
  min-width: 456px;
  justify-content: center;
  padding: 54px;
}

.l-title {
  font-size: 36px;
}

.l-sub-text {
  font-size: 16px;
  color: #4A4A4A;
}

.link {
  font-size: 14px;
}

.link-subtext {
  font-size: 14px;
  color: #4A4A4A;
}

.logo {
  padding: 30px;
}

.image {
  height: 558px;
}

.primary {
  height: 61px;
  width: 158px;
  border-radius: 10px;
  background-color: #69AE3B;
  color: #FFFFFF;
  font-size: 20px;
}

.alert-container {
  display: flex;
  width: 65%;
  justify-content: center;
  align-items: center;
}

.login-alert {
  color: #FF0000;
}
