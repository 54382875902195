.bottom-buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.back {
  width: 158px;
  height: 61px;
  border-radius: 10px;
  border: 1px solid #4A90E2;
  background-color: #FFFFFF;
  color: #4A90E2;
  margin-right: 37px;
}

.other {
  width: 158px;
  height: 61px;
  border-radius: 10px;
  border: 1px solid #4A90E2;
  background-color: #FFFFFF;
  color: #4A90E2;
}

.continue {
  width: 158px;
  height: 61px;
  border-radius: 10px;
  background-color: #69AE3B;
  color: #FFFFFF;
  border: none;
}
.signatures {
  width: 158px;
  height: 61px;
  border-radius: 10px;
  background-color: #FFFFFF;
  color: #69AE3B;
  border: 1px solid #69AE3B;
  margin-right: 37px;
}
.s-continue {
  width: 200px;
  height: 61px;
  border-radius: 10px;
  font-size: 18px;
  background-color: #69AE3B;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #FFFFFF;
  border: none;
}

.repeat {
  border: 1px solid #9B9B9B;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 200px;
  height: 61px;
  font-size: 18px;
  border-radius: 10px;
  background-color: #FFFFFF;
  color: #9B9B9B;
}