#lock-screen-link  {
  color: white;
  font-size: 1.2vw;
  margin-bottom: 15px;
}

.lock-icon {
  margin-left: 10px;
}

.link-container {
  display: flex;
  justify-content: flex-end;
}