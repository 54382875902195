#copy-right {
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  width: 235px;
  align-self: flex-end;
  margin-right: 30px;
  margin-bottom: 30px;
}

#copy-right-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}

#copy-right p {
  margin-bottom: 0;
}

#copy-right-logo {
  width: 20px;
  height: 43px;
}

#copy-right-text {
  align-self: flex-end;
  margin-right: 10px;
  opacity: 0.67;
}

#terms-container {
  margin-top: 10px;
}

#terms-container a {
  height: 16px;
  width: 194px;
  color: #FFFFFF;
  font-size: 14px;
  letter-spacing: -0.19px;
  line-height: 16px;
  text-align: center;
  margin-top: 10px;
}
