.status-bar {
  display: flex;
  justify-content: flex-end;
  width: 95%;
}

.patient-confirmation-container  {
  display: flex;
  flex-direction: row;
  width: 95%;
}

.signature-section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
}

.patient-status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
}

.status-text {
  font-size: 2vw;
  color: #000000;
}

.patient-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.image-container {
  width: 150px;
  height: 150px;
  background-color: gray;
  border-radius: 70px;
}

.name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.yes-no {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.name-title-text{
  font-size: 28px;
  color: #000000;

}

.name-text {
  font-size: 36px;
  color: #000000;
}

.button-text-container {
  display: flex;
  flex-direction: column;
}

.button-text {
  font-size: 28px;
  color: #000000;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
}

.confirm {
  width: 60px;
  height: 60px;
  background-color: #6BAD3D;
  border-radius: 50px;
}

.cancel {
  width: 60px;
  height: 60px;
  background-color: #9B9B9B;
  border-radius: 50px;
}

.signature-title-line {
  width: 40%;
  border: 1px solid #E5E5E5;
  height: 1px;
}

.signature-title-text {
  font-size: 36px;
  color: #000000;  
}

.signature-title-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.status-title-line {
    width: 35%;
    border: 1px solid #E5E5E5;
    height: 1px;
  }
  
.status-title-text {
    font-size: 1.5vw;
    color: #ffffff;  
}
  
.status-title-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.emojis-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.status-title-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 20px;
  align-items: center;
  width: 100%;
  background-color: #4A90E2;
}

.medication-confirmation-container {
  width: 100%;
}

.form-group label {
  color: #4A4A4A;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
}

.custom-switch {
  margin-left: 5px;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: transparent !important;
  background-color: rgba(41,146,69,0.47) !important;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(154, 204, 167, 0.39) !important;
}