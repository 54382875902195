.signature-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding:40px;
}

.button-container {
  width: 24%;
  display: flex;
  flex-direction: column;
  position: fixed;
  right: -10%;
}

.ok {
  width: 80px;
  height: 80px;
  background-color: #69AE3B;
  border-radius: 50px;
  margin-bottom: 10px;
}

.clear {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: #FFFFFF;
  border: 1px solid black;
}

.status-title-text-container-rn {
  height: 71px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EEF4FA;
  margin-top: 40px;
}

.status-title-text-rn {
  font-size: 20px;
  color: #4A4A4A;
}

.confirm-txt {
  font-size: 1.5vw;
}

.r-s-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.r-s-text {
  font-size: 28px;
}

.narcotic-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.update-inventory-btn {
  height: 51px;
  width: 184px;
  border: 1px solid #979797;
  border-radius: 14px;
  color: #4A90E2;
  background: none;

}

.update-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.update-title {
  font-size: 20px;
  font-family: Open Sans Bold;
}

.update-title-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.update-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
   width: 100%;
  align-items: center;
}

.update-med-txt {
  color: #4A4A4A;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.27px;
  line-height: 24px;
}

.update-input {
  height: 48px;
  width: 72px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #F3F3F3;
  text-align: center;
}

.update-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
}

.update-close-btn {
  height: 67px;
  width: 189px;
  border-radius: 10px;
  background-color: #DE5A48;
  color: #FFFFFF;
  font-size: 20px;
  font-family: Roboto Regular;
}

.update-update-btn {
  height: 67px;
  width: 189px;
  border-radius: 10px;
  background-color: #69AE3B;
  color: #FFFFFF;
  font-size: 20px;
  font-family: Roboto Regular;
}

.comments-container {
  height: 109px;
  width: 100%;
  border: 1px solid #CFCFCF;
  border-radius: 20px;
  background-color: #F8FAFC;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.comments-input {
  height: 50px;
  border: none;
  background-color: #F8FAFC;
  width: 80%;
  color: #4A4A4A;
  font-size: 1vw;
  padding: 20px;
  outline: none;
}