.refill-sign-btn {
  height: 33px;
  width: 56px;
  border: 1px solid #69AE3B;
  border-radius: 10px;
  background: none;
}

.signature-btn {
  height: 61px;
  width: 158px;
  border: 1px solid #69AE3B;
  border-radius: 10px;
  color: #4A4A4A;
  background: none;
  position: absolute;
  right: 23%;
}

.refill-sign-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.list-header-text-container-refills {
    width: 20%;
    text-align: center;
}

.options-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.exit {
  height: 53px;
  width: 149px;
  border: 1px solid #69AE3B;
  border-radius: 10px;
}

.options-exit-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.or-txt {
  color: #4A4A4A;
  font-family: Roboto;
  font-size: 25px;
  line-height: 29px;
  margin-right: 10px;
}

.options-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}