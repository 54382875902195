.header-container {
  display: flex;
  width: 100%;
  min-height: 181px;
}

.header-content-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.content-space {
  display: flex;
  justify-content: space-between;
}

.logo-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 30px;
}

.user-container {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 30px;
}

.user-name {
  font-size: 1.1vw;
  color: #FFFFFF;
}

.role {
  font-size: 0.7vw;
  color: #A3BBFF;
}

.h-lbl {
  font-size: 1vw;
  margin-right: 20%;
}

.log-out {
  width: 164px;
  height: 48px;
  border-radius: 23px;
  border: 1px solid #69AE3B;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.residence-txt {
  color: #FFFFFF;
  font-size: 2vw;
  font-weight: 300;
  letter-spacing: -0.32px;
  line-height: 28px;
}

.h-oval {
  height: 46px;
  width: 46px;
  border: 1px solid #69AE3B;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -8px;
}

.h-name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 50px;
}

.residence-txt-container {
  height: 50%;
  display: flex;
  align-items: center;

}

@media screen and (min-width: 992px) {
  .content-space {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .user-container {
    width: 50%;
    display: flex;
    justify-content: center;
    padding-right: 30px;
  }
  .logo-container {
    width: 50%;
    display: flex;
    justify-content: center;
    padding-left: 30px;
  }
}
