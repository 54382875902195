.list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.sc-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.list-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.list-content-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 10px;
}

.list-header-text {
  font-size: 1vw;
  color: #4A4A4A;
}

.list-header-text-container {
  width: 25%;
  text-align: center;
}

.t-small {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1vw;
}

.small-sign {
  width: 40%;
  justify-content: center;
  align-items: center;
  font-size: 80%;
  font-weight: 400;
  margin-left: 10px;
}

.s-t {
  width: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.large {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
}

.s-t-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}

.restore-btn {
  height: 38px;	
  width: 83px;	
  border: 1px solid #69AE3B;	
  border-radius: 10px;
  color: #4A4A4A;
  font-size: 12px;
  font-weight: bold;
}

.took-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;	
  width: 38px;	
  border-radius: 15px;	
  background-color: #4A90E2;
  border: none;
}

.t-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 30px;
  max-height: 38px;
  background-color: #69AE3B;
  border-radius: 10px;
  color: #ffffff;
  font-size: 1vw;
  font: bold;
  border: none;
}

.s-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 30px;
  max-height: 38px;
  background-color: #DE5A48;
  border-radius: 10px;
  color: #ffffff;
  font-size: 1vw;
  font: bold;
  border: none;
}

.l-btn {
  height: 38px;	
  width: 64.48px;	
  border-radius: 10px;	
  background-color: #F5A623;
  color: #ffffff;
  font-size: 12px;
  border: none;
}

.sc-med-text {
  font-size: 1vw;
}

.list-header-text-container-sc {
  width: 20%;
  text-align: center;
}

.reasons-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.s {
  margin-right: 5px;
}

.dim {
  opacity: 0.3;
}

.not-found {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
